import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ApiService } from '../api.service';
import { MatDialog, MatSnackBar, MatTableDataSource, MatPaginator, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-pvusers',
  templateUrl: './pvusers.component.html',
  styleUrls: ['./pvusers.component.css']
})
export class PVUsersComponent implements OnInit {

  constructor(private api:ApiService,private dialog: MatDialog,
    public snackbar: MatSnackBar) { }

  public ck:any = {};
  public ce:any ={};

  ngOnInit() {
    this.api.getUsers().subscribe(
      r=>{        
          this.users_=r.data;
          this.users = new MatTableDataSource(this.users_);
          this.users.paginator = this.paginator;   
        
        console.log(r);
      }
    );
  }

  addAdmin()
  {
    this.api.addAdmin(this.ck).subscribe(
      r=>
      {
        if(r.code==200)
        {
          this.snackbar.open("Адміністратор "+this.ck.name + ' створений!',"", { duration: 2000 });
          this.ngOnInit();
        }
      }
    );
  }
  editAdmin()
  {
    
    this.api.edtUser(this.ce).subscribe(
      r=>
      {
        if(r.code==200)
        {
          this.snackbar.open("Дані адміністратора "+this.ce.name + ' збережено!',"", { duration: 2000 });
          this.ngOnInit();
        }
      }
    );
  }

  prepareEdit(r: any) {
    this.ce = r;
  }

  deleteAdmin(r)
  {
    const dialogRef = this.dialog.open(DeleteAdminModel, {
      data: { name: r.name , id: r.id },
      width: "550px"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 0) {
        this.api.delUser(result.id).subscribe(
          r=>{
              this.snackbar.open("Адміністратор "+result.name + ' був видалений',"", { duration: 2000 });
              this.ngOnInit();
          }
          );

      }
    });
  }



  
  public users_:any = [];
  public displayedColumns = ["name","email","id"];

  @ViewChild("pagin",{read:false,static:false}) paginator: MatPaginator;
  users: MatTableDataSource<Object>;

}

@Component({
  selector: "modal",
  templateUrl: "../modal-views/remove-admin-modal.html"
})
export class DeleteAdminModel {
  constructor(
    public dialogRef: MatDialogRef<DeleteAdminModel>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close(0);
  }

  close() {
    this.dialogRef.close(0);
  }

  ok() {
    this.dialogRef.close(this.data);
  }
}