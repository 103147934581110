import {MatPaginatorIntl} from '@angular/material';
import {Injectable} from '@angular/core';

@Injectable()
export class CustPag extends MatPaginatorIntl {
  constructor() {
    super();

    this.itemsPerPageLabel = "Показувати по";
      this.nextPageLabel = "Наступна";
      this.previousPageLabel = "Попередня";
      this.lastPageLabel = "Остання";
      this.firstPageLabel = "Перша";
      this.changes.next();
  }

 

 getRangeLabel = (page: number, pageSize: number, length: number) =>  {
    if (length === 0 || pageSize === 0) {
      return `0 з ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} з ${length}`;
  }
}