import {Injectable} from '@angular/core';

const TOKEN = 'TOKEN';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
   getAll(): any {
    return JSON.parse(localStorage.getItem(TOKEN));
    
  }
  getCompanyID()
  {
    var p = JSON.parse(localStorage.getItem(TOKEN));    
    return p['company_id'];
  }
  static getID(): any {
    var p = JSON.parse(localStorage.getItem(TOKEN));
    return p['token'];
  }

  getName(): any {
    var p = JSON.parse(localStorage.getItem(TOKEN));    
    return p['name'];
  }

  setToken(token: Object): void {
    localStorage.setItem(TOKEN, JSON.stringify(token));
  }

  setExToken(tok:string ,token: Object): void {
    localStorage.setItem(tok, JSON.stringify(token));
  }

  delExToken(tok:string): void {
    localStorage.removeItem(tok);
  }

  getExToken(tok:string): any {
    var p = JSON.parse(localStorage.getItem(tok));    
    return p;
  }

  isLogged() {
    return localStorage.getItem(TOKEN) != null;
  }

  isAdmin() : any{
    return JSON.parse(localStorage.getItem(TOKEN))['perms']==1;
  }

  clearToken() {
    localStorage.clear();
  }

  logOff()
  {
    this.clearToken();
    window.location.reload();
  }
}