import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterViewComponent } from './register-view/register-view.component';
import { PVPersonViewComponent } from './pvperson-view/pvperson-view.component';
import { AdminViewComponent } from './admin-view/admin-view.component';
import { PVListComponent, DeleteDocTempTypeModel } from './pvlist/pvlist.component';
import { LoginViewComponent } from './login-view/login-view.component';
import { PVSearchComponent } from './pvsearch/pvsearch.component';
import { NeedAuthGuard } from './need-auth.guard';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material-module.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PVUsersComponent, DeleteAdminModel } from './pvusers/pvusers.component';
import { MatPaginatorIntl } from '@angular/material';
import { CustPag } from './ua-paginator-intl';

@NgModule({
  declarations: [
    AppComponent,
    RegisterViewComponent,
    PVPersonViewComponent,
    AdminViewComponent,
    PVListComponent,
    LoginViewComponent,
    PVSearchComponent,
    PVUsersComponent,
    DeleteDocTempTypeModel,
    DeleteAdminModel
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MaterialModule,
    BrowserAnimationsModule
  ],
  entryComponents:[DeleteDocTempTypeModel,DeleteAdminModel],
  providers: [NeedAuthGuard,{ provide: MatPaginatorIntl, useClass: CustPag}],
  bootstrap: [AppComponent]
})
export class AppModule { }
