import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ApiService } from '../api.service';
import { MatTableDataSource, MatPaginator, MatSort, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pvlist',
  templateUrl: './pvlist.component.html',
  styleUrls: ['./pvlist.component.css']
})
export class PVListComponent implements OnInit {

  constructor(private api:ApiService,private dialog: MatDialog,
    public snackbar: MatSnackBar,public http:HttpClient) { }

  public regions:any = [];
  public ck:any = {};
  public ce:any ={};

  ngOnInit() {
    this.api.getRegions().subscribe(
      r=>{
        this.regions=r.data;
      }
    );
  }
  addPV()
  {
    this.api.addPV(this.ck).subscribe(
      r=>
      {
        if(r.code==200)
        {
          this.snackbar.open("Приавтний виконавець "+this.ck.name + ' створений!',"", { duration: 2000 });
        }
      }
    );
  }
  editPV()
  {
    console.log(this.ce);
    this.api.edtPV(this.ce).subscribe(
      r=>
      {
        if(r.code==200)
        {
          this.snackbar.open("Дані приавтного виконавця "+this.ce.name + ' збережено!',"", { duration: 2000 });
        }
      }
    );
  }

  prepareEdit(r: any) {
    this.ce = r;
  }

  deletePV(r)
  {
    const dialogRef = this.dialog.open(DeleteDocTempTypeModel, {
      data: { name: r.name , id: r.id },
      width: "550px"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 0) {
        this.api.delPV(result.id).subscribe(
          r=>{
              this.snackbar.open("Приватний виконавець "+result.name + ' був видалений',"", { duration: 2000 });
              this.ngOnInit();
              this.search4exec();
          }
          );

      }
    });
  }

  search4exec()
  {
    console.log(this.search_query);
    this.api.getExecutors(this.search_query).subscribe(
      r=>{
        
          this.privateex_=r.data;

          this.privateex = new MatTableDataSource(this.privateex_);
          this.privateex.paginator = this.paginator;
          
        
        console.log(r);
      }
    );
  }

  getRegionByID(region_id)
  {
    for(let i=0;i<this.regions.length;i++)
    {
      if(this.regions[i].id==region_id)
      {
        return this.regions[i].name;
      }
    }
  }
  fileChange(event,v) {
    
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        let formData:FormData = new FormData();
        formData.append('file', file, file.name);
        let headers = new Headers();
        /** In Angular 5, including the header Content-Type can invalidate your request */
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        //let turl = window.location.protocol+"://"+window.location.hostname+'/api/index.php';
        let turl = "https://reestr.pv.net.ua/api/index.php";
        this.http.post(turl, formData)
            .subscribe(
                r =>  {
                  v.image=r['data']['imageUrl'];                  
                },
                error => console.log(error)
            )
    }
}

  
  public privateex_:any = [];
  public displayedColumns = ["name","region","id"];
  public search_query = {name:"",surname:"",patronum:"",region_id:""};

  @ViewChild("pagin",{read:false,static:false}) paginator: MatPaginator;
  privateex: MatTableDataSource<Object>;

}

@Component({
  selector: "modal",
  templateUrl: "../modal-views/remove-modal.html"
})
export class DeleteDocTempTypeModel {
  constructor(
    public dialogRef: MatDialogRef<DeleteDocTempTypeModel>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close(0);
  }

  close() {
    this.dialogRef.close(0);
  }

  ok() {
    this.dialogRef.close(this.data);
  }
}
