import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { MatTableDataSource, MatSort, MatPaginatorIntl, MatPaginator } from '@angular/material';
import { CustPag } from '../ua-paginator-intl';

@Component({
  selector: 'app-pvsearch',
  templateUrl: './pvsearch.component.html',
  styleUrls: ['./pvsearch.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustPag }
 ]
})
export class PVSearchComponent implements OnInit {

  constructor(private api:ApiService) { }


  ngOnInit() {
    this.api.getRegions().subscribe(
      r=>{
        this.regions=r.data;
      }
    );
  }

  chv()
  {
    return (this.search_query.name==""&&this.search_query.surname==""&&this.search_query.patronum==""&&this.search_query.region_id=="");
  }

  search4exec()
  {
    console.log(this.search_query);
    this.api.getExecutors(this.search_query).subscribe(
      r=>{
        
          this.privateex_=r.data;

          this.privateex = new MatTableDataSource(this.privateex_);
          this.privateex.paginator = this.paginator;
          this.privateex.sort = this.sort;
          
        
        console.log(r);
      }
    );
  }

  getRegionByID(region_id)
  {
    for(let i=0;i<this.regions.length;i++)
    {
      if(this.regions[i].id==region_id)
      {
        return this.regions[i].name;
      }
    }
  }

  public regions:any = [];
  public privateex_:any = null;
  public displayedColumns_mob = ["total"];
  public displayedColumns = ["rating1","name","region","sum_min","office"];
  public search_query = {name:"",surname:"",patronum:"",region_id:"",filter_sum:"",filter_rakh:false,filter_pidhid:""};

  @ViewChild("pagin",{read:false,static:false}) paginator: MatPaginator;
  privateex: MatTableDataSource<Object>;

  @ViewChild("sortCol",{read:false,static:false}) sort: MatSort;

}
