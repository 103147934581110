import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.css']
})
export class AdminViewComponent implements OnInit {

  constructor(public cs:CustomerService) { }

  ngOnInit() {
  }

  public navbarOpen = false;

  toggleNavbar(){
    this.navbarOpen=!this.navbarOpen;
  }

}
