import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { CustomerService } from '../customer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.css']
})
export class LoginViewComponent implements OnInit {
  ngOnInit(): void {
    
  }

  email = '';
  password = '';

  constructor(private api: ApiService, private customer: CustomerService, private router: Router) {
  }

  tryLogin() {
    this.api.getAuth(
      this.email,
      this.password
    )
      .subscribe(
        r => {
          if(r.code==200)
          {
            if (r.data) {
              this.customer.setToken(r.data);
              this.router.navigateByUrl('/admin');
            }
          }
          else{
            alert("Невірний логін та\\або пароль!");
          }
        },
        r => {
          alert(r.error.error);
        });
  }

}
