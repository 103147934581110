import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterViewComponent } from './register-view/register-view.component';
import { PVPersonViewComponent } from './pvperson-view/pvperson-view.component';
import { AdminViewComponent } from './admin-view/admin-view.component';
import { PVListComponent } from './pvlist/pvlist.component';
import { LoginViewComponent } from './login-view/login-view.component';
import { PVSearchComponent } from './pvsearch/pvsearch.component';
import { NeedAuthGuard } from './need-auth.guard';
import { PVUsersComponent } from './pvusers/pvusers.component';


const routes: Routes = [
  {
    path:"admin",
    component:AdminViewComponent,
    
    children:[
      {
        path:"",
        component:PVListComponent,
        canActivate:[NeedAuthGuard]
      },
      {
        path:"users",
        component:PVUsersComponent,
        canActivate:[NeedAuthGuard]
      }
      
    ]
  },
  {
    path:"login",
    component:LoginViewComponent
  },
  {
    path:"",
    component:RegisterViewComponent,
    children:[
      {
        path:':id',
        component:PVPersonViewComponent
      },
      {
        path:"",
        component:PVSearchComponent
      }
    ]
  }
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
