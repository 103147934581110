import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pvperson-view',
  templateUrl: './pvperson-view.component.html',
  styleUrls: ['./pvperson-view.component.css']
})
export class PVPersonViewComponent implements OnInit {

  constructor(private api:ApiService,private route: ActivatedRoute,private titleService: Title) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.api.getRegions().subscribe(
      r=>{
        this.regions=r.data;
        this.api.getPVbyID(id).subscribe(
          r=>{
            this.pvd=r.data[0];
            this.pvd['colour']="black";

            this.titleService.setTitle(this.pvd.surname+" "+this.pvd.name+" "+this.pvd.patronum+" – Рейтинг приватних виконавців");

            if(this.pvd.rating1==1)
            {
              this.pvd['colour']="limegreen";
            }
            if(this.pvd.rating1==2)
            {
              this.pvd['colour']="gold";
            }
            if(this.pvd.rating1==3)
            {
              this.pvd['colour']="orange";
            }
            if(this.pvd.rating1==4)
            {
              this.pvd['colour']="red";
            }
            if(this.pvd.rating1==5)
            {
              this.pvd['colour']="DimGrey";
            }
          }
        );
      }
    );
    
  }

  togglerating()
  {
    this.ratingvis=!this.ratingvis;
  }

  toggle_win(num:number)
  {    
    if(num==1)
    {
      this.tab_1=!this.tab_1;
    }
    if(num==2)
    {
      this.tab_2=!this.tab_2;
    }
    if(num==3)
    {
      this.tab_3=!this.tab_3;
    }
    if(num==4)
    {
      this.tab_4=!this.tab_4;
    }
  }

  //elementor-active
  public tab_1 = false;
  public tab_2 = false;
  public tab_3 = false;
  public tab_4 = false;

  public pvd = {colour:"black",id:"",surname:"",name:"",patronum:"",rating_history:"",rating1:0,rating2:0,region_id:"",website:"",fb_url:"",payment_account:"",prepay_account:"",description:"",tel1:"",tel2:"",email:"",work_hours:"",office_hours:"",office:"",sum_min:"",sum_max:"",more_than_year:"",image:""};
  public ratingvis=false;
  public regions:any = [];
  getRegionByID(region_id)
  {
    for(let i=0;i<this.regions.length;i++)
    {
      if(this.regions[i].id==region_id)
      {
        return this.regions[i].name;
      }
    }
  }

}
