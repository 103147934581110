import { HttpClient, HttpParams, HttpHeaders, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginResultModel } from './LoginResultModel';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  getUsers()
  {
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()})
    .append("action", "get_users");
    return this.http.post<LoginResultModel>(this.link,httpParams);
  }

  edtUser(dt:any){
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()})
    .append("action", "edt_user")
    .append("data", JSON.stringify(dt));
    return this.http.post<LoginResultModel>(this.link,httpParams);
  }

  addAdmin(dt:any){
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()})
    .append("action", "add_user")
    .append("data", JSON.stringify(dt));
    return this.http.post<LoginResultModel>(this.link,httpParams);
  }

  delUser(id:number){
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()})
    .append("action", "del_user")
    .append("data", JSON.stringify({id:id}));
    return this.http.post<LoginResultModel>(this.link,httpParams);
  }

  addPV(dt:any){
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()})
    .append("action", "add_pv")
    .append("data", JSON.stringify(dt));
    return this.http.post<LoginResultModel>(this.link,httpParams);
  }

  edtPV(dt:any){
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()})
    .append("action", "edt_pv")
    .append("data", JSON.stringify(dt));
    return this.http.post<LoginResultModel>(this.link,httpParams);
  }

  delPV(id:number){
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()})
    .append("action", "del_pv")
    .append("data", JSON.stringify({id:id}));
    return this.http.post<LoginResultModel>(this.link,httpParams);
  }

  getPVbyID(id:number)
  {
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()})
    .append("action", "get_pv")
    .append("data", JSON.stringify({id:id}));
    return this.http.post<LoginResultModel>(this.link,httpParams);
  }
  
  getAuth(l:string,p:string)
  {
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()})
    .append("action", "get_auth")
    .append("data", JSON.stringify({login:l,password:p}));
    return this.http.post<LoginResultModel>(this.link,httpParams);
  }
  
  getRegions()
  {
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()})
    .append("action", "get_regions");
    return this.http.post<LoginResultModel>(this.link,httpParams);
  }

  getExecutors(search_query:any)
  {
    let httpParams = new HttpParams({encoder: new HttpUrlEncodingCodec()})
    .append("action", "get_executors")
    .append("data", JSON.stringify(search_query));
    return this.http.post<LoginResultModel>(this.link,httpParams);
  }

  constructor(private http: HttpClient) {

  }
 private link:string = 'https://reestr.pv.net.ua/api/';
 
  
}